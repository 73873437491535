<template>
<div>
  <div class="page-banner">
    <img v-lazy="banner" class="img-center">
  </div>
  <div class="container padding-80">
    <div class="common-title">
      <h1>{{ $t("menu.careers") }}</h1>
      <div class="common-line"></div>
    </div>
    <div class="career-list">
      <ul>
        <li v-for="item in careers" :key="item">
<!--          <h3>{{ $t("jobTitle") }}</h3>-->
          <div class="career-txt job-title">
            <p><b>{{item.communications_technician}}</b></p>
          </div>
          <h3>{{ $t("jobNature") }} :</h3>
          <div class="career-txt">
            <p>{{item.job_purpose}}</p>
          </div>
          <h3>{{ $t("requirement") }} :</h3>
          <div class="career-txt requirement">
            <p v-for="(requirement,index) in item.skills_qualifications" :key="index">
              <span></span>{{requirement}}
            </p>
          </div>
        </li>

      </ul>
    </div>
  </div>



</div>
</template>

<script>
import {getCareer} from "@/network/main";

export default {
  name: "Careers",
  data(){
    return{
      banner:{},
      careers:[],
    }
  },
  created() {
    getCareer().then(res => {
      this.banner = res.data.data.banner;
      this.careers = res.data.data.data;
    })
  },

}
</script>

<style scoped>
.career-list ul li{
  margin-top: 50px;
  width: 100%;
}
.career-list ul li h3{
  padding-bottom: 10px;

  font-size: 16px;
}
.career-txt{
  padding-bottom: 20px;
}
.requirement p{
  display: flex;
  align-items: center;
  line-height: 2;
}
.requirement span{
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background: #666;
  margin-right: 5px;
}
.job-title p{
  font-size: 20px;
}

@media only screen and (min-width:0px) and (max-width:767px){
  .career-list ul li{
    margin-top: 30px;
   }

}




</style>